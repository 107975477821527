html, body {
    margin: 0 !important;
}

footer {
    height: 60px;
    display: flex;
    margin: 0 25px;
    align-items: center;
    border-top: 1px solid #e6ebf1;
    justify-content: space-between;
}

.app-content {
    min-height: calc(100vh - 70px);
    position: relative;

    @media (min-width: 768px) {
        padding: 25px;
    }
}

.app-header {
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, .15);
    z-index: 1000;
    padding: 0 12px;

    @media (min-width: 1024px) {
        padding: 0 50px 0 0;
    }
}

.app-header-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.nav {
    display: flex;
    width: calc(100% - 250px);
    justify-content: space-between;
}

.nav-left, .nav-right {
    display: flex;
}

.ellipsis-dropdown {
    cursor: pointer;
    font-size: 21px;
    transform: rotate(90deg);
    display: inline-block;
    line-height: 1;
    padding: 0.3125rem;
    border-radius: 50rem;
    transition: background-color .3s ease;
}

.ellipsis-dropdown:hover, .ellipsis-dropdown:focus {
    background-color: rgba(0, 0, 0, .05);
}

.ant-layout {
    background: #f9f9f9;
}

.avatar-status-name {
    font-weight: 500;
    color: #1a3353;
    text-decoration: none;
}

.ant-card {
    margin: 0 0 20px;
}

.auth-container {
    height: 100vh;
}

iframe {
    display: none;
}

a {
    text-decoration: none !important;
}

.mdxeditor-popup-container {
    z-index: 1000;
}